import analytics from 'analytics'
import {signIn, useSession} from 'next-auth/react'
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {DigitalServiceUser, IdentityDetailsResponse, WSError} from 'types/types'
import * as Sentry from '@sentry/react'
import {useRouter} from 'next/router'
import {getCallbackUrl} from 'utils/authentication'

type CustomerAffiliateContextType = {
  availableUsers: DigitalServiceUser[] | null
  currentUser: DigitalServiceUser | undefined
  selectedAffiliateName: string | undefined
  setSelectedAffiliateName: Dispatch<SetStateAction<string | undefined>>
  error: Boolean
}

const CustomerAffiliateContext = createContext<CustomerAffiliateContextType>(
  {} as CustomerAffiliateContextType,
)

export function CustomerAffiliateProvider({
  mockProps,
  children,
}: {
  mockProps?: {
    availableUsers: DigitalServiceUser[] | undefined
    selectedAffiliateName: string | undefined
  }
  children: any
}) {
  const {data, status} = useSession()

  const router = useRouter()

  // Analytics
  const [didSendLoginAnalyticsEvent, setDidSendLoginAnalyticsEvent] =
    useState<Boolean>(false)

  // Available users/affiliates.
  const [availableUsers, setAvailableUsers] = useState<
    DigitalServiceUser[] | null
  >(mockProps?.availableUsers ?? null)
  // Selected user/affiliate.
  const [currentUser, setCurrentUser] = useState<DigitalServiceUser>()
  // Selected affiliate name (for menu dropdown).
  const [selectedAffiliateName, setSelectedAffiliateName] = useState<
    string | undefined
  >(mockProps?.selectedAffiliateName)

  const [error, setError] = useState<Boolean>(false)

  useEffect(() => {
    if (data && !didSendLoginAnalyticsEvent && currentUser) {
      setDidSendLoginAnalyticsEvent(true)
      analytics.sendUserLogInEvent('yes', currentUser?.digitalServiceUserId)
    }
  }, [currentUser, didSendLoginAnalyticsEvent, data])

  useEffect(() => {
    checkAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkAuthentication = useCallback(() => {
    if (
      status === 'unauthenticated' ||
      Date.now() > (data?.tokenExp as number) * 1000
    ) {
      signIn(
        'azure-ad-b2c',
        {
          callbackUrl: getCallbackUrl(router),
        },
        {prompt: 'login', ui_locales: `${router.locale}`},
      )
    }
  }, [status, data?.tokenExp, router])

  useEffect(() => {
    checkAuthentication()

    const fetchIdentityDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_EXTERNAL_API}/portalbff/identity-details`,
          {
            method: 'GET',
            headers: new Headers({
              Authorization: 'Bearer ' + data?.accessToken,
              'Ocp-Apim-Subscription-Key':
                process.env.NEXT_PUBLIC_EXTERNAL_API_SUBSCRIPTION_KEY!,
              Accept: '*/*',
            }),
          },
        )

        if (response.ok) {
          const data: IdentityDetailsResponse = await response.json()
          setAvailableUsers(data.digitalServiceUsers)
          // Take the first user as the default to display.
          const user = data.digitalServiceUsers[0]
          if (user) {
            setCurrentUser(user)
            setSelectedAffiliateName(user.customerAffiliate.name)
          } else {
            throw {
              msg:
                'Context: Invalid data. No service users were found. ' +
                response.statusText,
              status: response.status,
            }
          }
        } else {
          throw {
            msg: 'Error fetching identity details: ' + response.statusText,
            status: response.status,
          }
        }
      } catch (err: unknown) {
        console.log(err)
        Sentry.captureException(err)

        if ((err as WSError).status === 401) {
          console.log('User session expired. Please reauthenticate.')
          signIn(
            'azure-ad-b2c',
            {
              callbackUrl: getCallbackUrl(router),
            },
            {prompt: 'login', ui_locales: `${router.locale}`},
          )
        }
        setError(true)
      }
    }

    if (status === 'authenticated' && !availableUsers) {
      fetchIdentityDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableUsers, data?.accessToken, status])

  useEffect(() => {
    // Set the current affiliate user when the selected name in the dropdown
    // changed.
    setCurrentUser(
      availableUsers?.filter(
        user => user.customerAffiliate.name === selectedAffiliateName,
      )[0],
    )
  }, [availableUsers, selectedAffiliateName])

  const value = {
    availableUsers,
    currentUser,
    selectedAffiliateName,
    setSelectedAffiliateName,
    error,
  }

  return (
    <CustomerAffiliateContext.Provider value={value}>
      {children}
    </CustomerAffiliateContext.Provider>
  )
}

export const useCustomerAffiliateContext = () => {
  return useContext(CustomerAffiliateContext)
}
