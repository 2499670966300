import '../styles/globals.css'
import dynamic from 'next/dynamic'
import type {AppProps} from 'next/app'
import {storyblokInit, apiPlugin} from '@storyblok/react'
import {appWithTranslation} from 'next-i18next'
import {SessionProvider} from 'next-auth/react'
import {CustomerAffiliateProvider} from 'context/CustomerAffiliateProvider'

const MainMenu = dynamic(
  () => import('../components/Layout/Navigation/MainMenu'),
)
const MainMenuItem = dynamic(
  () => import('../components/Layout/Navigation/MainMenuItem'),
)
const Logo = dynamic(() => import('../components/Layout/Navigation/Logo'))
const Cards = dynamic(() => import('../components/Nodes/Cards/Cards'))
const Profile = dynamic(() => import('../components/Nodes/Profile/Profile'))
const Dashboard = dynamic(
  () => import('../components/Nodes/Dashboard/Dashboard'),
)
const Company = dynamic(() => import('../components/Nodes/Company/Company'))
const FooterContainer = dynamic(
  () => import('../components/Layout/Footer/FooterContainer'),
)
const FooterTileContainer = dynamic(
  () => import('../components/Layout/Footer/FooterTileContainer'),
)

const Page = dynamic(() => import('../components/Page'))
const Tokens = dynamic(() => import('../components/Nodes/Cards/Tokens'))

const CtaContainer = dynamic(
  () => import('../components/shared/Cta/CtaContainer'),
)
const ContentBlockContainer = dynamic(
  () => import('../components/shared/TitleTextImage/ContentBlockContainer'),
)
const Invoices = dynamic(() => import('../components/Nodes/Invoices/Invoices'))
const Cookies = dynamic(() => import('../components/Nodes/Cookies'))
const CardsDetail = dynamic(
  () => import('../components/Nodes/Cards detail/CardsDetail'),
)
const ErrorPage = dynamic(
  () => import('../components/Layout/ErrorPage/ErrorPage'),
)

const AlertContainer = dynamic(() => import('../components/Layout/Alert'))

const RequestAdditionalFuelCardB2b = dynamic(
  () =>
    import(
      '../components/Nodes/Cards/CardsRequestAdditonalFuelForm/CardsRequestAdditionalFuelForm'
    ),
)
const RequestDetailsChange = dynamic(
  () => import('../components/Nodes/Company/CompanyRequestChangeDetails'),
)

const CtaNoLinkContainer = dynamic(
  () => import('../components/shared/Cta/CtaNoLinkContainer'),
)

const components = {
  main_menu: MainMenu,
  main_menu_item: MainMenuItem,
  logo: Logo,
  list_of_cards_page: Cards,
  form_request_additonal_fuel_card: RequestAdditionalFuelCardB2b,
  form_request_details_change: RequestDetailsChange,
  card_details_page: CardsDetail,
  title_text_image: ContentBlockContainer,
  page_header: ContentBlockContainer,
  tokens: Tokens,
  profile_page: Profile,
  company_preferences: Company,
  cta: CtaContainer,
  cta_no_link: CtaNoLinkContainer,
  page: Page,
  footer: FooterContainer,
  footer_tile: FooterTileContainer,
  list_of_invoices_page: Invoices,
  'cookie-iframe-url': Cookies,
  dashboard_page: Dashboard,
  server_error_page: ErrorPage,
  alert: AlertContainer,
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY!,
  use: [apiPlugin],
  components,
  bridge: true,
})

function App({Component, pageProps: {session, ...pageProps}}: AppProps) {
  return (
    <SessionProvider session={session}>
      <CustomerAffiliateProvider>
        <Component {...pageProps} />
      </CustomerAffiliateProvider>
    </SessionProvider>
  )
}

export default appWithTranslation(App)
